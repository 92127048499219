<!-- 用户状态标签 -->
<template>
  <div>
    <crud ref="crud" :config="config"  @audit="audit" @auditAll="auditAll"></crud>
    <approve-modal ref='approveModal' @close='auditClose'/>
  </div>
</template>


<script>
import Crud from '@/components/crud/Crud'
import ApproveModal from '@/views/biz/driver/modules/ApproveModal.vue'
import { approveDriverAll } from '@/api/userCar/userCar'

export default {
  name: "index",
  components: {
    Crud,ApproveModal
  },
  data() {
    return {
      config: {
        search: [
          { field: 'nickname', name: '用户昵称' },
          { field: 'mobile', name: '司机电话' },
        ],
        button: [
          { name: '一键审核',  event: 'auditAll', color: 'primary' }
        ],
        table: {
          api: '/user/user-cars/driverPage',
          col: [
            { type: 'index' },
            { field: 'nickname', name: '用户昵称' },
            { field: 'mobile', name: '司机电话',  },
            { field: 'email', name: '邮箱',  },
            { field: 'avatar', name: '头像链接', type: 'img', },
            { field: 'taxId', name: '税号',  },
            { field: 'address', name: '联络地址',  },
            { field: 'driverAuthStatus', name: '司机认证状态' ,type: 'tag',
              tags: [{ name: '待审核', color: 'yellow' },
                { name: '通过', color: 'green' },
                { name: '拒绝', color: 'red' },]},
            { field: 'createTime', name: '创建时间'  },
            {
              type: 'button', button: [
                { name: '审核', event: 'audit' , checkShow: (rowData) => rowData.driverAuthStatus == 0 },
              ]
            },
          ]
        },
      },
    }
  },
  methods: {
    audit(button, rowData) {
      this.$refs.approveModal.audit(rowData);
    },
    auditAll(){
      console.log("======一键审核====")
      let that = this;
      this.$confirm({
        title: '审核确认',
        content: '确认全部一键审核吗?',
        okText: '确认',
        cancelText: '取消',
        okType: 'danger',
        onOk: () => {
          approveDriverAll( {}).then((res) => {
            if (res.success) {
              that.$message.success(res.message);
            } else {
              that.$message.warning(res.message)
            }
          }).finally(() => {
            this.$refs.crud.getList();
            that.confirmLoading = false

          })
        },
      });

    },
    auditClose() {
      this.$refs.crud.getList();
    },
  }

}
</script>